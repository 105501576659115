// import i18n from "i18next"
// import { initReactI18next } from 'react-i18next';
// import common_az from './translations/az/translation.json'
// import common_en from './translations/en/translation.json'
// import common_rus from './translations/rus/translation.json'

// const resources = {
//   az: {
//     translation: common_az
//   },
//   en: {
//     translation: common_en
//   },
//   rus: {
//     translation: common_rus
//   }
// }

// i18n
//   .use(initReactI18next)
//   .init({
//     resources,
//     lng: 'az',
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false
//     }
//   })

// export default i18n


// import i18next from 'i18next'
// import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'

// i18next
// .use(initReactI18next)
// .use(LanguageDetector)
// .init({
//   debug:true,
//   fallbackLng:'az',
//   resources: {
//     az: {
//       translation: {
//         description: 'Onda sizə StartPoint yardımçı olacaq.'
//       }
//     },
//     en: {
//       translation: {
//         description: 'hi'
//       }
//     },
//   }
// })

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const availableLanguages = ['az', 'en', 'rus']
const option = {
  order: ['navigator', 'htmlTag', 'path', 'subdomail'],
  checkWhitelist: true
}
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'az',
    debug: true,
    whitelist: availableLanguages,
    detection: option,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;

