import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n'
// import i18next from 'i18next';
// import '../src./i18n'  

// const lang = localStorage.getItem('lang') || 'az'
// i18next.changeLanguage(lang)


import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <App />
   
  </React.StrictMode>
);

serviceWorker.unregister();